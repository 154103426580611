/* Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

/* General */
html,
body {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  font-family: 'Open Sans', sans-serif;
}

/* Links */
a {
  color: #ec6c5d;
  text-decoration: none;
}

a:hover {
  color: #f7897d;
  text-decoration: none;
}

/* Buttons */
.btn-primary {
  background-color: #3a4e48;
  border-color: #3a4e48;
}

.btn-primary:hover {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #3a4e48;
  border-color: #3a4e48;
  opacity: 0.5;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: #3a4e48;
  border-color: #3a4e48;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

/*  Custom buttons */

.button-orange {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
}

.button-orange:hover {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.button-orange.btn-primary.disabled,
.button-orange.btn-primary:disabled {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  opacity: 0.5;
}

/* Login Page */
.logo {
  max-width: 50%;
  margin-top: 4em;
  margin-bottom: 4em;
}

.reset-password {
  float: right;
}


/* Spinners */
.custom-spinner {
  color: #3a4e48;
}

.custom-spinner-small-modal {
  color: #3a4e48;
  margin-left: 79px;
}

.data-loading-spinner {
  color: #3a4e48;
  height: 50px;
  width: 50px;
  border-width: 6px;
}

.data-loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Or whatever height you want */
}

/* Dashboard */

.dashboard-title {
  margin: 1rem 0;
}

.dashboard-image {
  width: 300px;
}

.welcome-row {
  margin-bottom: 100px;
  margin-top: 50px;
}

.dashboard-header {
  text-align: center;
  font-size:x-large;
  font-weight: 600;
}

.dashboard-custom-button-container {
  align-items: center;
  text-align: center;
}

.dashboard-list {
  max-width: fit-content;
  margin-bottom: 2rem;
}

.dashboard-list .list-group-item {
  padding: 1rem;
  color: #3a4e48;
}

/* Custom Button */
.custom-button-container{
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  text-align: center;
  border: 2px;
  border-radius: 10px;
  margin: 12px;
  padding: 5px;
  height: 150px;
}

.buttons-container {
  max-width: 970px;
  text-align: center;
}

.custom-button-image {
  fill: #3a4e48;
  width: 50px;
  height: 60px;
}

.dashboard-body{
  align-items: center;
  text-align: center;
}

.image-row {
  justify-content: center;
  margin-bottom: 50px;
}

.custom-anchor {
  color: #212529 !important;
  text-decoration: none !important;
}

.custom-button-header {
  font-weight: 600;
  margin-top: 5px;
  color: #3a4e48;
}

.custom-button-description {
  font-size: small;
  margin: 5px;
}

.custom-buttons-container {
  align-items: center;
  text-align: center;
}

.section-header {
  font-size: large;
  font-weight: 600;
}

.custom-svg {
  filter: invert(28%) sepia(38%) saturate(213%) hue-rotate(111deg) brightness(92%) contrast(94%);
}

/* Reset Password */

.reset-password-container {
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  top: 50px;
}

.reset-password-container > * {
  flex-shrink: 0;
  max-width: 100%;
}

.error-col {
  min-height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.reset-password-field {
  margin: 10px;
  width: 60%;
  margin: auto;
  margin-bottom: 7px;
  margin-top: 7px;
}

.reset-password-header {
  color: #3a4e48;
  margin: 10px;
}

.reset-password-button {
  margin: 10px;
  width: 150px;
}

.go-back {
  margin-top: 20px;
}

/* Main Wrapper */
.main-wrapper {
  background-color: #f3f2ef;
  min-height: 100vh;
  min-width: 100%;
}

/* Header */
.header {
  background-color: #ffffff;
  display: flex;
  box-shadow:
    0 1.6px 3.6px rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px rgba(0, 0, 0, 0.108);
}

.sign-out {
  text-decoration: none;
  color: #ec6c5d;
  margin-left: 4px;
}

.sign-out:hover{
  color:#b0594f;
  cursor: pointer;
}

.sign-out-item{
  width: max-content;
  height: 100%;
  margin-bottom: 0 !important;
  background-color: #3a4e48;
  align-items: center;
}

.sign-out-item-no-bg {
  width: max-content;
  height: 100%;
  margin-bottom: 0 !important;
  align-items: center;
}

.company-text {
  color: #ffffff;
  margin-bottom: 0 !important;
}

.logo-container {
  padding: 15px;
}

p.no-margin{
  margin-bottom: 0;
}

.sign-out-container {
  margin-top: 0;
  margin-bottom: 0;
}

.sign-out-text:hover{
  cursor: pointer;
}

/* Forgot Password */

.parent-container {
  height: 200px;
  justify-content: center;
}

.center-content-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main content */
.main-content {
  background-color: #f3f2ef;
  margin-top: 2em;
  padding: 1em;
}

@media (max-width: 768px) {
  .main-content {
    padding: 0em;
    margin-top: 0em;
  }
}

/* Sidebar */

/* User wrapper */
.user-wrapper {
  display: flex;
  flex-direction: column;
}

.user-wrapper p:first-child {
  margin-bottom: 0;
}

/* Menu */
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
    padding-right: 12px;
  }
}

.menu-wrapper .navbar-nav .nav-link,
.mobile-menu .nav-link {
  color: #3a4e48;
}

.mobile-menu .dropdown-menu {
  border: none;
  padding: 0.25rem 0;
  margin-left: 1em;
}

.custom-nav-link:hover{
  color: #847f7f !important;
}

.custom-nav-link.active{
  font-weight: bold;
}

/* Dropdown */
.navbar-dropdown .dropdown-menu {
  background-color: transparent;
  border: none;
  padding: 0 0 0 1em;
}

.navbar-dropdown:hover{
  color: #D3D3D3 !important;
}

.dropdown-item {
  color: #3a4e48;
  padding: 0.25rem 0 0.25rem 0;
}

.dropdown-item:hover {
  color: #3a4e48;
}

.dropdown-menu-open {
  margin-bottom: 125px;
}

.navbar-dropdown .dropdown-toggle.btn-primary {
  background-color: transparent;
  border: none;
  filter: none;
  color: #3a4e48;
  padding: 0.5rem 0 0.5rem 0;
  transition: none;
}

.navbar-dropdown .dropdown-item.active,
.navbar-dropdown .dropdown-item:active {
  background-color: transparent;
  color: #3a4e48;
}

.navbar-dropdown .dropdown-item:focus,
.navbar-dropdown .dropdown-item:hover {
  background-color: transparent;
}

.content-wrapper {
  min-height: 100vh;
}

.heading-intro {
  margin-top: 2em;
}

/* Accordion */
.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #212529;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

/* Modal */
.modal-backdrop {
  background-color: transparent;
}

/* Code format */

pre {
  background-color: #f8f9fa;
  padding: 15px;
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

/* Toast */

.toast-container {
  height: 200px;
  display: flex;
  justify-content: center;
}

.toast-b-right {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  width: auto;
}

.toast-background {
  background-color: white;
}

.toast-success {
  background-color: white;
}

.toast-error {
  background-color: #FF0000;
  color: #f3f2ef;
}

.input-error {
  border-color: #FF0000;
  border-radius: 5px;
}



/* Pagination */

.pagination .page-item.active .page-link {
  background-color: #ec6c5d;
  border-color: #ec6c5d;
  color: #ffffff;
  z-index: 0;
}

.pagination .page-link {
  color: #ec6c5d;
}

.pagination .page-link:hover {
  z-index: 0;
}


/* Validation */

.error-field {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 12px;
}

.error-container {
  height: 15px;
}

.error-container-login {
  height: 7px;
}

.error-text-align-left {
  text-align: left;
}

.error-container-settings {
  height: 15px;
  align-items: center;
  display: flex;
  margin-top: 9px;
}


/* Settings */

.sticky-header{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2000;
  padding-top: 2px;
}

/* Connection Config */

.connection-field {
  height: 75px;
}

.search-btn-col {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-right: 45px;
}

/* Dropdown */

.custom-z-index {
  z-index: 999;
  position: relative;
}

.custom-z-index-shipment {
  z-index: 998;
  position: relative;
}

.custom-z-index-tax-area {
  z-index: 997;
  position: relative;
}

.custom-z-index-tax-area-eu {
  z-index: 996;
  position: relative;
}

.custom-z-index-tax-area-rest {
  z-index: 995;
  position: relative;
}

.custom-z-index-unit-of-measure {
  z-index: 996;
  position: relative;
}

.custom-z-index-crew {
  z-index: 995;
  position: relative;
}

.custom-z-index-payment-terms {
  z-index: 1000;
  position: relative;
}

.payment-terms-select .dropdown-menu {
  z-index: 2000; /* Custom z-index */
}

.react-select__option--is-selected {
  background-color: white !important;
  color: black !important;
}

.react-select__option--is-focused {
  border: none;
  background-color: #847f7f !important;
  color: white !important;
}

/* Table */

.enhanced-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.table-loader{
  text-align: center !important;
}

.enhanced-table th, .enhanced-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.enhanced-table thead th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 2;
}

.custom-card-body {
  padding: 0px !important;
}

.table-button {
  margin-right: 10px;
}

/* Log */

.log-header{
  margin-left: 7px;
}

.log-field {
  margin-right: 7px;
}


/* Setup Guide */

.anchor-selected {
  color: #3a4e48 !important;
  text-shadow: 0.2px 0 #3a4e48, -0.2px 0 #3a4e48, 0 0.2px #3a4e48, 0 -0.2px #3a4e48;
}

.content-container {
  padding: 10px;
}

.content-section-image {
  width: 80%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-section {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 50px;
}

 .content-section:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 1px;
  margin-left: 17px;
  opacity: 50%;
  background-color: #ec6c5d;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #000000a6 ;
}

.content-section:before {
  top: 0;
  left: 0;
}

.content-section:after {
  top: 0;
  left: 0;
}

.content-header {
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 15px;
  color: #3a4e48;
}

.toc-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  position: sticky;
  top: 0;
}


.nav-margin {
  margin-top: 10px;
}

.toc-col {
  width: fit-content;
  position: relative;
}

.custom-spinner {
  color: #3a4e48;
  width: 2.085rem;
  height: 2.085rem;
}

.table-card {
  padding: 0 !important;
}


/* Errors */
.error-message {
  color: #FF0000;
}

.input-error {
  border-color: #FF0000;
  border-radius: 5px;
}

.error-size {
  min-height: 25px;
}

.required-star {
  font-size: 12px;
  color: red;
  margin-left: 3px;
}

/* Toast */

.toast-container {
  height: 200px;
  display: flex;
  justify-content: center;
}

.toast-success {
  background-color: white;
}

.toast-error {
  background-color: #FF0000;
  color: #f3f2ef;
}

.toast-b-right {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  width: auto;
}


/* Sync overview */

.sync-overview .result-dropdown {
  margin-right: 2em;
}

.sync-overview .pagination {
  margin-left: 2em;
}

.sync-overview .form-select.dropdown {
  width: 20%;
  float: right;
}

@media (max-width: 768px) {
  .sync-overview .form-select.dropdown {
    width: 50%;
  }
}

/* Tabs styles */

.custom-tab .nav-link.active {
  background-color: #3a4e48 !important;
  color: #ffffff;
}

.custom-tab .nav-link {
  background-color: #ddd;
  color: #212529;
}

.custom-tab .nav-link:hover {
  background: #f2f2f2;
}

/* Draggable */
.draggable-container{
  border: 1px solid #dfe2e6; 
  padding: 10px;
}

.draggable-item{
  padding: 10px;
  margin: 5px;
  background-color: lightgrey;
  cursor: move;
}